<template>
  <svg
    :width="width"
    :height="height"
    viewBox="0 0 150 151"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M150 75.4785C150 116.9 116.421 150.479 75 150.479C33.5786 150.479 0 116.9 0 75.4785C0 34.0572 33.5786 0.478516 75 0.478516C116.421 0.478516 150 34.0572 150 75.4785Z"
      :fill="bgColor"
    />
    <g clip-path="url(#clip0_687_346)">
      <path
        d="M49.375 61.7285V108.604C49.375 111.182 51.4844 113.291 54.0625 113.291H96.25C98.8281 113.291 100.938 111.182 100.938 108.604V61.7285H49.375ZM63.4375 103.916H58.75V71.1035H63.4375V103.916ZM72.8125 103.916H68.125V71.1035H72.8125V103.916ZM82.1875 103.916H77.5V71.1035H82.1875V103.916ZM91.5625 103.916H86.875V71.1035H91.5625V103.916Z"
        :fill="iconColor"
      />
      <path
        d="M102.109 47.8535H86.875V41.9941C86.8713 41.0629 86.4997 40.1708 85.8412 39.5123C85.1827 38.8538 84.2906 38.4822 83.3594 38.4785H66.9531C66.0219 38.4822 65.1298 38.8538 64.4713 39.5123C63.8128 40.1708 63.4412 41.0629 63.4375 41.9941V47.8535H48.2031C47.2715 47.856 46.3787 48.2272 45.7199 48.886C45.0612 49.5447 44.69 50.4375 44.6875 51.3691V57.2285H105.625V51.3691C105.623 50.4375 105.251 49.5447 104.593 48.886C103.934 48.2272 103.041 47.856 102.109 47.8535ZM82.1875 47.8535H68.125V43.227H82.1875V47.8535Z"
        :fill="iconColor"
      />
    </g>
    <defs>
      <clipPath id="clip0_687_346">
        <rect
          width="75"
          height="75"
          fill="white"
          transform="translate(38 37.4785)"
        />
      </clipPath>
    </defs>
  </svg>
</template>

<script>
export default {
  props: {
    width: {
      type: [Number, String],
      default: 18,
    },
    height: {
      type: [Number, String],
      default: 18,
    },
    iconColor: {
      type: String,
      default: "#ff6961",
    },
    bgColor: {
      type: String,
      default: "#ff69612e"
    }
  },
};
</script>