<template>
  <!-- :for="value" -->
  <div :class="this.classAdd">
    <label
      class="text-md font-proximaMedium text-mediumGrey mb-3"
      :for="value"
      >{{ label }}</label
    >
    <input
      :type="type"
      :id="value"
      :placeholder="getplaceholder"
      :value="value"
      class="w-full rounded-sm px-3 py-3 my-1 text-sm placeholder-lightGrey font-proximaMedium rounded-md mb-4"
      @input="$emit('update', $event.target.value)"
      style="height: 70px"
      :readonly="disabled"
    />
    <FormError :errors="error" />
  </div>
</template>
<script>
import FormError from "../warning/FormError.vue";
export default {
  props: {
    type: {
      type: String,
      default: "text",
    },
    disabled: {
      type: Boolean,
      default: false,
    },

    value: String,
    label: String,
    classAdd: String,
    error: String,
    placeholder: String,
  },
  components: {
    FormError,
  },
  data() {
    return {};
  },
  computed: {
    classNames() {
      let classNames = [];
      classNames.push(`${this.classAdd}`);
      return classNames;
    },
    getplaceholder() {
      return this.placeholder ? this.placeholder : this.label;
    },
  },

  model: {
    prop: "value",
    event: "update",
  },
};
</script>
