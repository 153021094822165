<template>
  <svg
    :width="width"
    :height="height"
    viewBox="0 0 150 150"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <circle cx="75" cy="75" r="75" :fill="iconColor" />
    <g clip-path="url(#clip0_687_347)">
      <path
        d="M62.2162 102.57C60.0873 102.571 58.0455 101.725 56.5414 100.218L39.3846 83.0677C37.5385 81.221 37.5385 78.2275 39.3846 76.3808C41.2312 74.5348 44.2247 74.5348 46.0714 76.3808L62.2162 92.5257L104.929 49.8133C106.775 47.9672 109.769 47.9672 111.615 49.8133C113.462 51.66 113.462 54.6534 111.615 56.5001L67.891 100.218C66.3869 101.725 64.3452 102.571 62.2162 102.57Z"
        fill="white"
      />
    </g>
    <defs>
      <clipPath id="clip0_687_347">
        <rect
          width="75"
          height="75"
          fill="white"
          transform="translate(38 38)"
        />
      </clipPath>
    </defs>
  </svg>
</template>

<script>
export default {
  props: {
    width: {
      type: [Number, String],
      default: 18,
    },
    height: {
      type: [Number, String],
      default: 18,
    },
    iconColor: {
      type: String,
      default: "#0AC0CB",
    },
  },
};
</script>