<template>
  <div class="flex justify-between">
    <div class="w-full md:w-1/12 px-4 md:pr-8 lg:pr-16"></div>
    <div class="w-full md:w-5/12 px-4 md:pr-8 lg:pr-16">
      <div class="mb-2 flex flex-col justify-center items-center">
        <InputImage
          @blurred="inputImg"
          :title="$t('global_add_profile_picture')"
        />
      </div>

      <FormError :errors="errors.save" />
      <div class="flex justify-between">
        <Input
          :label="$t('global_firstname') + '*'"
          v-model="firstName"
          classAdd="w-full mr-4 flex flex-col justify-start items-start relative"
          :error="errors.firstName"
        />
        <Input
          :label="$t('global_lastname') + '*'"
          v-model="lastName"
          classAdd="w-full ml-4 flex flex-col justify-start items-start relative"
          :error="errors.lastName"
        />
      </div>
      <div class="flex justify-between">
        <Input
          :label="$t('global_phone_number') + '*'"
          v-model="account.phoneNo"
          classAdd="w-full mr-4 flex flex-col justify-start items-start relative"
          :error="errors.phoneNo"
          :disabled="true"
        />
        <Input
          :label="$t('global_email_address') + '*'"
          v-model="account.emailAddress"
          classAdd="w-full ml-4 flex flex-col justify-start items-start relative"
          :error="errors.emailAddress"
          :disabled="true"
        />
      </div>
      <div class="flex justify-between">
        <Input
          v-if="account.practiceName"
          :label="$t('account_business_name')"
          v-model="account.practiceName"
          class="w-full mr-4 flex flex-col justify-start items-start relative"
          :error="errors.businessName"
        />
        <Input
          v-else
          :label="$t('account_business_name')"
          v-model="account.businessName"
          class="w-full mr-4 flex flex-col justify-start items-start relative"
          :error="errors.businessName"
        />
        <div
          v-if="account.practiceType"
          class="w-full ml-4 flex flex-col justify-start items-start relative"
        >
          <label
            class="text-md font-proximaMedium text-mediumGrey mb-3"
            for="country"
            >{{ $t("service_directoty_service_category") + "*" }}</label
          >
          <select
            class="w-full bg-white text-mediumGrey rounded-md px-2 py-3 my-1 text-sm"
            style="height: 70px"
            v-model="account.practiceType"
            @change="inputSelectCountry()"
          >
            <option value="" disabled selected>
              {{ $t("service_directoty_select_category") }}
            </option>
            <option
              v-for="item in serviceTypesList"
              :value="item.id"
              :key="item.id"
            >
              {{ item.name }}
            </option>
          </select>
          <FormError :errors="errors.serviceType" />
        </div>
        <div
          v-else
          class="w-full ml-4 flex flex-col justify-start items-start relative"
        >
          <label
            class="text-md font-proximaMedium text-mediumGrey mb-3"
            for="country"
            >{{ $t("service_directoty_service_category") + "*" }}</label
          >
          <select
            class="w-full bg-white text-mediumGrey rounded-md px-2 py-3 my-1 text-sm"
            style="height: 70px"
            v-model="account.serviceType"
            @change="inputSelectCountry()"
          >
            <option value="" disabled selected>
              {{ $t("service_directoty_select_category") }}
            </option>
            <option
              v-for="item in serviceTypesList"
              :value="item.id"
              :key="item.id"
            >
              {{ item.name }}
            </option>
          </select>
          <FormError :errors="errors.serviceType" />
        </div>
      </div>

      <Input
        :label="$t('account_website')"
        v-model="account.website"
        class="mt-5 flex flex-col justify-start items-start relative"
        :error="errors.website"
      />

      <Input
        :label="$t('global_street_address')"
        v-model="account.streetAddress"
        class="mt-5 flex flex-col justify-start items-start relative"
        :error="errors.streetAddress"
      />

      <div class="mt-5 flex justify-between">
        <div class="w-full mr-4 flex flex-col justify-start items-start">
          <label
            class="text-md font-proximaMedium text-mediumGrey mb-3"
            for="country"
            >{{ $t("global_country") }}</label
          >
          <select
            class="w-full bg-white text-mediumGrey rounded-sm px-2 py-3 my-1 text-sm"
            style="height: 70px"
            v-model="account.country"
            @change="inputSelectCountry()"
          >
            <option value="" disabled selected>
              {{ $t("global_select_options") }}
            </option>
            <option
              v-for="item in countriesList"
              :value="item.id"
              :key="item.id"
            >
              {{ item.name }}
            </option>
          </select>
          <FormError :errors="errors.country" />
        </div>
        <div class="w-full ml-4 flex flex-col justify-start items-start">
          <label
            class="text-md font-proximaMedium text-mediumGrey mb-3"
            for="state"
            >{{ $t("global_state") }}</label
          >
          <select
            class="w-full bg-white text-mediumGrey rounded-sm px-2 py-3 my-1 text-sm"
            style="height: 70px"
            v-model="account.state"
          >
            <option value="" disabled selected>
              {{ $t("global_select_options") }}
            </option>
            <option v-for="item in statesList" :value="item.id" :key="item.id">
              {{ item.name }}
            </option>
          </select>
          <FormError :errors="errors.state" />
        </div>
      </div>
      <div class="mt-5 flex justify-between">
        <Input
          :label="$t('global_city')"
          v-model="account.city"
          classAdd="w-full mr-4 flex flex-col justify-start items-start relative"
          :error="errors.city"
        />
        <Input
          :label="$t('global_postal_code')"
          v-model="account.postalCode"
          classAdd="w-full ml-4 flex flex-col justify-start items-start relative"
          :error="errors.postalCode"
        />
      </div>
    </div>
    <div class="w-full md:w-6/12 px-4 md:pr-8 lg:pr-16">
      <div
        class="md:h-1/5 lg:h-1/3 flex justify-center items-center mb-20 mt-8"
      >
        <img
          class="w-6/12 h-auto hidden md:flex md:w-11/12 lg:w-10/12"
          src="@/assets/images/perpetual-platform.svg"
          alt="perpetual-logo"
        />
      </div>
      <Input
        :label="$t('account_business_tax_id') + '*'"
        v-model="taxID"
        class="mt-5 flex flex-col justify-start items-start relative"
        :error="errors.taxID"
      />

      <div
        class="w-6/12 flex justify-start text-md font-proximaMedium text-mediumGrey mt-5"
      >
        <label class="container"
          >{{ $t("service_directoty_filter_section_1") }}
          <input type="checkbox" v-model="account.english_multilang" />
          <span class="checkmark"></span>
        </label>
      </div>

      <Input
        v-model="account.langs"
        placeholder="English, German, Spanish..."
        class="mt-1 flex flex-col justify-start items-start relative"
        :error="errors.langs"
      />
      <div
        class="w-6/12 flex justify-start text-md font-proximaMedium text-mediumGrey mt-5"
      >
        <label class="container"
          >{{ $t("service_directoty_filter_section_4") }}
          <input type="checkbox" v-model="account.special_equipment" />
          <span class="checkmark"></span>
        </label>
      </div>
      <Input
        placeholder="24-hour Holter, digital x-ray..."
        v-model="account.equipments"
        class="mt-1 flex flex-col justify-start items-start relative"
        :error="errors.equipments"
      />
      <div class="flex justify-between mt-5">
        <div
          class="w-6/12 flex justify-start text-md font-proximaMedium text-mediumGrey"
        >
          <label class="container"
            >{{ $t("service_directoty_filter_section_6") }}
            <input type="checkbox" v-model="account.loyalty_program" />
            <span class="checkmark"></span>
          </label>
        </div>
        <div
          class="w-6/12 flex justify-start text-md font-proximaMedium text-mediumGrey"
        >
          <label class="container"
            >{{ $t("service_directoty_filter_section_2") }}
            <input type="checkbox" v-model="account.service_24_7" />
            <span class="checkmark"></span>
          </label>
        </div>
      </div>
      <div class="flex justify-between">
        <div
          class="w-6/12 flex justify-start text-md font-proximaMedium text-mediumGrey"
        >
          <label class="container"
            >{{ $t("service_directoty_filter_section_5") }}
            <input type="checkbox" v-model="account.deferred_payment" />
            <span class="checkmark"></span>
          </label>
        </div>
        <div
          class="w-6/12 flex justify-start text-md font-proximaMedium text-mediumGrey"
        >
          <label class="container"
            >{{ $t("service_directoty_filter_section_3") }}
            <input type="checkbox" v-model="account.accept_credit_card" />
            <span class="checkmark"></span>
          </label>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import InputSelect from "../../forms/InputSelect.vue";
import Input from "../../forms/Inputh_.vue";
import InputRadio from "../../forms/InputRadio.vue";
import InputImage from "../../forms/InputImage.vue";
import "vue-tel-input/dist/vue-tel-input.css";
import { VueTelInput } from "vue-tel-input";
import FormError from "../../warning/FormError.vue";

import { updateServiceProvider } from "../../../services/serviceProviderService";
import { getStates } from "../../../services/stateServices";
import { getCountries } from "../../../services/countriesServices";
import {
  saveServiceProvider,
  saveVet,
} from "../../../services/serviceProviderService";
import { getbusinessTypes } from "../../../services/serviceDirectoryService";
import { loginSP } from "../../../services/authService";
import { mapActions, mapGetters } from "vuex";

export default {
  name: "signInAfterLogin",
  components: {
    InputSelect,
    InputRadio,
    InputImage,
    VueTelInput,
    Input,
    FormError,
  },
  data() {
    return {
      firstName: "",
      lastName: "",
      taxID: "",
      account: {
        /*   
        emailAddress: "emailAddress",
        phoneNo: "",
        */
      },

      errors: {},
      countriesList: [],
      statesList: [],
      serviceTypesList: [],
      provider: "email",
      coordinates: { lng: 3.05997, lat: 36.7762 },
    };
  },
  async created() {
    console.log("+++++", this.account);
    this.$getLocation({}).then((coordinates) => {
      this.coordinates = coordinates;
    });

    await getbusinessTypes().then((result) => {
      if (this.account.businessName) {
        this.serviceTypesList = this.serviceTypesList.concat(
          result.data.business_service_provider
        );
      } else {
        this.serviceTypesList = this.serviceTypesList.concat(
          result.data.business_vet
        );
      }
    });

    const a = await getCountries();
    this.countriesList = a.data;

    let code = this.$route.params["account"];
    const { data: account } = await loginSP(code);

    this.account = account;

    if (this.account.country) {
      console.log("this.account.country :", this.account.country);
      const c = await getStates(this.account.country);
      this.statesList = c.data;
    }

    console.log(this.account);
  },
  methods: {
    ...mapActions({
      signInAfterLogin: "auth/signInAfterLogin",
    }),
    async inputSelectCountry() {
      const a = await getStates(this.account.country);
      this.statesList = a.data;
    },
    async inputSelectState(val) {
      this.state = val;
    },
    inputImg(val) {
      this.account.profileImage = val;
    },

    validate() {
      const errors = {};

      if (this.firstName.trim() === "")
        errors.firstName = "FirstName is required";
      if (this.lastName.trim() === "") errors.lastName = "LastName is required";
      if (this.account.streetAddress == "")
        errors.streetAddress = "Address  is required";
      if (this.account.country === "") errors.country = "Country is required";
      if (this.account.state === "") errors.state = "State  is required";
      if (this.account.city.trim() === "") errors.city = "City  is required";
      if (this.account.postalCode.trim() === "")
        errors.postalCode = this.$t("global_error_postal_code");
      if (this.account.businessName && this.account.practiceName)
        errors.businessName = this.$t("account_error_business_name");
      if (this.taxID.trim() === "")
        errors.taxID = this.$t("account_business_tax_id") + " is required";

      return Object.keys(errors).length === 0 ? null : errors;
    },
    async submitForm(image) {
      const errors = this.validate();
      this.errors = errors || {};
      if (errors) {
        window.scrollTo({ top: 40, behavior: "smooth" });
        return;
      }

      try {
        let d = {};
        if (this.account.businessName) {
          d = await saveServiceProvider(
            this.firstName,
            this.lastName,
            this.provider,
            image,
            this.account,
            this.taxID,
            this.checkboxNews ? 1 : 0,
            this.coordinates.lng,
            this.coordinates.lat
          );
        } else {
          d = await saveVet(
            this.firstName,
            this.lastName,
            this.provider,
            image,
            this.account,
            this.taxID,
            this.checkboxNews ? 4 : 5, //? 1 : 0,
            this.coordinates.lng,
            this.coordinates.lat
          );
        }

        console.log(d);

        localStorage.removeItem("token");
        localStorage.removeItem("email");
        localStorage.removeItem("role");

        this.signInAfterLogin(d.data.login_data);
        this.$router.push({ path: "/sppassivedashboard/new" });
      } catch (error) {
        window.scrollTo({ top: 40, behavior: "smooth" });
        this.errors.save = "save error.";
      }
    },
  },
};
</script>

<style>
/* The container */
.container {
  display: block;
  font-family: "Milliard Medium";
  color: "#354E57";
  position: relative;
  padding-left: 10px;
  margin-bottom: 12px;
  cursor: pointer;
  font-size: 15px;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}

/* Hide the browser's default checkbox */
.container input {
  position: absolute;
  opacity: 0;
  cursor: pointer;
  height: 0;
  width: 0;
}

/* Create a custom checkbox */
.checkmark {
  position: absolute;
  top: 3px;
  left: 0;
  height: 18px;
  width: 18px;
  background-color: #fff;
}

/* On mouse-over, add a grey background color */
.container:hover input ~ .checkmark {
  background-color: #ccc;
}

/* When the checkbox is checked, add a blue background */
.container input:checked ~ .checkmark {
  background-color: #0ac0cb;
}

/* Create the checkmark/indicator (hidden when not checked) */
.checkmark:after {
  content: "";
  position: absolute;
  display: none;
}

/* Show the checkmark when checked */
.container input:checked ~ .checkmark:after {
  display: block;
}

/* Style the checkmark/indicator */
.container .checkmark:after {
  left: 7px;
  top: 4px;
  width: 5px;
  height: 8px;
  border: solid white;
  border-width: 0 2px 2px 0;
  -webkit-transform: rotate(45deg);
  -ms-transform: rotate(45deg);
  transform: rotate(45deg);
}

.vti__dropdown {
  border: 1px solid #ccc;
  border-radius: 0.125rem 0 0 0.125rem;
}
.vue-tel-input {
  border-radius: 0.125rem;
  border: none;
  font-size: 0.875rem;
}
.vti__input {
  padding: 0.75rem 10px 0.75rem;
  border-radius: 0 0.125rem 0.125rem 0;
}
.vue-tel-input:focus-within {
  box-shadow: none;
  border-color: none;
  outline: auto;
}
</style>
