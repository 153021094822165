<template>
 <!--   <div class="mt-2 mb-6 md:my-0 flex flex-col md:flex-row items-start">  -->
    <!--  <SectionLeft />  -->
 
    <!--  <div class="w-full mt-4 md:mt-0 flex">
      <div class="w-full pt-0 md:pt-12 mb-2 flex items-center">
        <div>
          <h1>Welcome to PerPETua</h1>
        </div>
        <div>
          <h3>Complete your profile to get started!</h3>
        </div>
      </div>
    </div> -->

   
      <AccountInfo />
    
   
   <!--  <img
      class="mt-8 md:hidden m-auto"
      src="@/assets/images/dataservice-logo.svg"
      alt="dataservice-logo"
    /> -->
 <!--  </div> -->
</template>
  
  <script>
import AccountInfo from "../components/account/businessprofile/AccountInfo.vue";

export default {
  props: {
    data: {},
  },
  components: {
    AccountInfo,
  },
};
</script>