<template>
  <div class="mb-10 flex flex-col justify-center items-center">
    <div>
      <h1 class="text-5xl text-mediumGrey font-proximaMedium py-7">
        {{ $t("global_welcome_title") }}
      </h1>
      <h2 class="text-2xl text-mediumGrey font-proximaMedium">
        {{ $t("business_profile_complete_profile") }}
      </h2>
    </div>

    <div class="logo">
      <img
        class="h-32 w-auto pt-8 hidden md:flex"
        src="@/assets/images/dataservice-logo.svg"
        alt="dataservice-logo"
      />
    </div>

    <div class="mb-10 mt-10 w-11/12 mb-8 md:w-10/12">
      <!--   <div
        class="mb-2 flex flex-col justify-center items-center"
        v-if="!editable"
      >
        <img
          class="mb-2 rounded-full w-24 h-24"
          style="width: 120px; height: 120px"
          rel="prefetch"
          :src="require(`@/assets/images/profilepic-icon.png`)"
          alt="profile picture"
        />
        <h4
          class="
            mt-2
            font-proximaMedium
            text-mediumGrey text-md text-center
            cursor-pointer
          "
          @click="editAccount"
        >
          {{ $t("global_add_profile_picture") }}
        </h4>
      </div>
      <InputImage
        @blurred="inputImg"
        :title="$t('global_edit_profile_picture')"
        v-else
      /> -->
      <!-- <h1 class="text-xl font-proximaMedium" v-if="!editable">
        {{ $t("account_title") }}
      </h1>
      <h1 class="text-xl font-proximaMedium" v-else>{{$t('edit_account_title')}}</h1> -->
      <AccountForms class="mt-8" ref="child" />
      <div class="mb-10 flex flex-col justify-center items-center">
        <div
          class="
            w-4/12
            flex
            items-start
            justify-start
            text-md
            font-proximaMedium
            text-mediumGrey
          "
        >
          <label class="container">
            {{ $t("sign_up_read_text_begin") }}
            <span
              class="text-right text-blue cursor-pointer"
              @click="tosCheck = !tosCheck"
              >{{ $t("global_read") }}</span
            >
            <br />
            {{ $t("sign_up_read_text_end") }}

            <input
              type="checkbox"
              v-model="checked"
              v-on:input="tosCheck = false"
            />
            <span class="checkmark"></span>
          </label>
        </div>
        <div
          class="
            w-4/12
            flex
            items-start
            justify-start
            text-md
            font-proximaMedium
            text-mediumGrey
          "
        >
          <AccordionSection :tosCheck="tosCheck" />
        </div>
        <div
          class="
            w-4/12
            flex
            items-start
            justify-start
            text-md
            font-proximaMedium
            text-mediumGrey
          "
        >
          <label class="container"
            >{{ $t("business_profile_text") }}
            <input type="checkbox" />
            <span class="checkmark"></span>
          </label>
        </div>
        <div
          class="
            border-t border-lightGrey20
            mt-6
            w-4/12
            mb-8
            md:w-4/12
            justify-center
          "
        >
          <SubmitButton
            fontSize="text-lg"
            paddingY="py-6"
            paddingX="px-5"
            :title="$t('account_complete_registration_button')"
            background="bg-lightGrey"
            class="cursor-not-allowed"
            v-if="!checked"
          />
          <SubmitButton
            fontSize="text-lg"
            paddingY="py-6"
            paddingX="px-5"
            :title="$t('account_complete_registration_button')"
            background="bg-primary"
            @click.native="editAccount"
            v-else
          />
        </div>
      </div>
    </div>
    <Modals :show="showModal" @close="showModal = false">
      <template #header>
        <h3>custom header</h3>
      </template>
    </Modals>
  </div>
</template>

<script>
import AccountForms from "./AccountForms.vue";
import InputImage from "../../forms/InputImage.vue";
import SubmitButton from "../../buttons/SubmitButton.vue";
import BorderedButton from "../../buttons/BorderedButton.vue";
import Modals from "../../modals/Modals.vue";
import AccordionSection from "../../accordion/AccordionSection.vue";

export default {
  components: {
    AccountForms,
    InputImage,
    SubmitButton,
    BorderedButton,
    Modals,
    AccordionSection,
  },
  data() {
    return {
      image: "",
      tosCheck: true,
      showModal: false,
      checked: false,
    };
  },
  methods: {
    inputImg(val) {
      this.image = val;
    },
    editAccount() {
      this.$refs.child.submitForm(this.image);
      console.log("hi !!");
    },
  },
};
</script>

<style>
.logo {
  position: absolute;
  top: 10px;
  left: 150px;
}
/* The container */
.container {
  display: block;
  font-family: "Milliard Medium";
  color: "#354E57";
  position: relative;
  padding-left: 0px;
  margin-bottom: 12px;
  cursor: pointer;
  font-size: 15px;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}

/* Hide the browser's default checkbox */
.container input {
  position: absolute;
  opacity: 0;
  cursor: pointer;
  height: 0;
  width: 0;
}

/* Create a custom checkbox */
.checkmark {
  position: absolute;
  top: 3px;
  left: 0;
  height: 18px;
  width: 18px;
  background-color: #fff;
}

/* On mouse-over, add a grey background color */
.container:hover input ~ .checkmark {
  background-color: #ccc;
}

/* When the checkbox is checked, add a blue background */
.container input:checked ~ .checkmark {
  background-color: #0ac0cb;
}

/* Create the checkmark/indicator (hidden when not checked) */
.checkmark:after {
  content: "";
  position: absolute;
  display: none;
}

/* Show the checkmark when checked */
.container input:checked ~ .checkmark:after {
  display: block;
}

/* Style the checkmark/indicator */
.container .checkmark:after {
  left: 7px;
  top: 4px;
  width: 5px;
  height: 8px;
  border: solid white;
  border-width: 0 2px 2px 0;
  -webkit-transform: rotate(45deg);
  -ms-transform: rotate(45deg);
  transform: rotate(45deg);
}

.vti__dropdown {
  border: 1px solid #ccc;
  border-radius: 0.125rem 0 0 0.125rem;
}
.vue-tel-input {
  border-radius: 0.125rem;
  border: none;
  font-size: 0.875rem;
}
.vti__input {
  padding: 0.75rem 10px 0.75rem;
  border-radius: 0 0.125rem 0.125rem 0;
}
.vue-tel-input:focus-within {
  box-shadow: none;
  border-color: none;
  outline: auto;
}
.fixed {
  position: fixed;
  top: 0;
  left: 0;
  width: 300px;
}
</style>
